import React, { useState, useEffect, useReducer, useRef } from "react";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useSelector } from "react-redux";
import { Form, Button, Input, Select, Tooltip, Popconfirm, Modal } from "antd";
const { Option } = Select;
const { TextArea } = Input;
// const { confirm } = Modal;
import { useTranslation, Trans } from "react-i18next";

// import { FlattenObject as flattenObject } from "@/utils/FlattenObject";
import Variables from "@/components/variables";

const outputDataInfo = {
  requestId: "Unique identifier of the HTTP request",
  umid: "Unique identifier of the message",
  status: "Status of the message request",
  description: "Descriptive message on the status of the message",
};

const ACTIONS = {
  INITIALIZE_FORM: "initialize-form",
  UPDATE_VALUE: "update-value",
};

function reducer(formData, action) {
  const { item, value } = action.payload;
  switch (action.type) {
    case ACTIONS.INITIALIZE_FORM:
      return { ...action.payload };
    case ACTIONS.UPDATE_VALUE:
      return { ...formData, [item]: value };
    default:
      return formData;
  }
}

const SmsForm = ({
  node,
  subaccountOptions,
  onUpdateNodeValue,
  sidebarScrollWrapper,
  onDeleteNode,
  stepNameList,
  onUpdateStepNameList,
}) => {
  const [form] = Form.useForm();
  const { elements } = useSelector((state) => state.flow);

  const sourceInputRef = useRef(null);
  const destinationInputRef = useRef(null);
  const textInputRef = useRef(null);

  const { t } = useTranslation();

  const outputDataInfots = {
    requestId: t("automation.output_info.requestId"),
    umid: t("automation.output_info.umid"),
    status: t("automation.output_info.status"),
    description: t("automation.output_info.description"),
  };

  const [toggleExpandOutput, setToggleExpandOutput] = useState(false);
  const [formData, dispatch] = useReducer(reducer, {});

  // Set 'formData' to the values of 'form instance' on mount
  useEffect(() => {
    dispatch({
      type: ACTIONS.INITIALIZE_FORM,
      payload: form.getFieldsValue(true),
    });

    // // Populate map node with default form items
    // if (!Object.keys(elements[node.id]?.inputs || {}).length) {
    //   const inputs = form.getFieldsValue();
    //   onUpdateNodeValue({ id: node.id, inputs }, true);
    // }

    // Set scrollTop to 0
    sidebarScrollWrapper.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Re-render fields if selectedNode is updated but has same type with previous selectedNode
  useEffect(() => {
    form.resetFields();

    sidebarScrollWrapper.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [node]);

  const onChangeFormValue = (item, value) => {
    dispatch({
      type: ACTIONS.UPDATE_VALUE,
      payload: { item, value },
    });
  };

  const onSubmitForm = (values) => {
    form.validateFields().then((values) => {
      // Update store node data
      const { id } = node;
      const newData = { ...elements[id] };

      let outputs = node?.data?.outputs || {};

      // outputs = {
      //   // ...outputs,
      //   // [`${values?.nameId}_source`]:
      //   //   values.source || "{{data.payload.source}}",
      //   // [`${values?.nameId}_destination`]:
      //   //   values.destination || "{{data.payload.destination}}",
      //   // // [`${values?.nameId}_body`]: values.text || "{{data.payload.body}}",
      //   // [`${values?.nameId}_step_requestId`]: "{{step.requestId}}",
      //   // [`${values?.nameId}_step_umid`]: "{{step.umid}}",
      //   // [`${values?.nameId}_step_status`]: "{{step.status}}",
      //   // [`${values?.nameId}_step_description`]: "{{step.description}}",
      // };

      onUpdateNodeValue({
        id,
        inputs: { ...newData.inputs, ...values },
        outputs,
      });

      // Update the master list of step names
      let nameList = [...stepNameList];
      const arrayIndex = nameList.indexOf(node?.data?.nameId || id);
      nameList.splice(arrayIndex, 1, values?.nameId);
      onUpdateStepNameList(nameList);
    });
  };

  const onSubmitFormFail = (values) => {
    // console.log("Failed to submit form");
  };

  const onConfirmDelete = () => {
    onDeleteNode(node.id || "");
  };

  const onDragStartTag = (e) => {
    e.dataTransfer.setData("variable", e.target.dataset.value);
  };

  const onDragOverInput = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDropInput = (e, fieldItem) => {
    e.preventDefault();
    const variable = e.dataTransfer.getData("variable");
    const { value, selectionEnd, selectionStart } = e.target;
    const newValue =
      value.slice(0, selectionStart) + variable + value.slice(selectionEnd);

    // Update values
    form.setFieldsValue({ [fieldItem]: newValue });
    onChangeFormValue(fieldItem, newValue);
  };

  const [variables, setVariables] = useState({});

  useEffect(() => {
    setVariables({ currId: node.id, elements, currOutputs: formData.outputs });
  }, [node, elements, formData.outputs]);

  return (
    <>
      <header className="bg-gradient-to-tr from-blue-500 to-indigo-500 px-5 pt-7 pb-14 w-96">
        <small className="text-xs text-red-200 tracking-widest">
          {t("automation.sms_configuration")}
        </small>
        <h1 className="font-bold text-lg text-white tracking-wider pr-20 leading-snug">
          {t("automation.modify_what")}
        </h1>
      </header>
      <section className="bg-gray-100 p-5 w-96">
        <div
          className="bg-white flex flex-col rounded shadow -mt-14"
          style={{ height: toggleExpandOutput ? "auto" : "150px" }}
        >
          <div className="overflow-hidden py-3 px-5 flex flex-col">
            <h2 className="font-bold text-sm text-gray-900 flex items-center">
              <span className="text-white bg-8x8-red-500 w-8 h-8 flex justify-center items-center rounded mr-2 shadow">
                <i className="material-icons text-xl text-black">data_object</i>
              </span>
              {t("automation.expected_output")}
            </h2>
            <ul className="list-none mt-3 space-y-2">
              {Object.keys(outputDataInfo).map((info) => (
                <li
                  className="leading-loose text-sm text-gray-800"
                  key={`info-${info}`}
                >
                  <code className="font-mono text-xs p-1 mr-1 bg-yellow-100 text-yellow-800 rounded-sm inline-block">
                    {info}
                  </code>{" "}
                  - {outputDataInfots[info]}
                </li>
              ))}
            </ul>
            <a
              className="mt-5 text-xs text-indigo-500 inline-block ml-auto"
              target="_blank"
              href="https://developer.8x8.com/connect/reference/send-sms"
            >
              {t("automation.sms_documentation")}
            </a>
          </div>
          <div className="-mb-3 flex justify-center relative">
            <div className="-mt-3 p-2 w-full bg-opacity-50 absolute bottom-6 rounded-b bg-gradient-to-t from-white via-white to-transparent" />
            <Button
              type="dashed"
              size="small"
              shape="round"
              className="text-xs"
              onClick={() => setToggleExpandOutput(!toggleExpandOutput)}
            >
              {toggleExpandOutput
                ? t("app_labels.hide")
                : t("app_labels.show_more")}
            </Button>
          </div>
        </div>
      </section>
      <section className="w-96">
        <div className="bg-gray-100 p-5 pb-0">
          <h3 className="text-blue-500 text-xs mb-1 font-bold">
            {t("column_labels.variables")}
          </h3>
          <p className="text-sm text-gray-800">
            {t("automation.pick_and_drag")}{" "}
            <strong>{t("fields.sender_id")}</strong>,{" "}
            <strong>{t("column_labels.destinations").split("|")[0]}</strong>,{" "}
            {t("app_labels.or")} <strong>{t("column_labels.message")}</strong>
          </p>
        </div>
        <Variables
          className="bg-gray-100 p-4 sticky top-0 z-10"
          variables={variables}
          handleDragStart={onDragStartTag}
        />
        <Form
          form={form}
          layout="vertical"
          name="sms-form"
          autoComplete="off"
          onFinish={onSubmitForm}
          onFinishFailed={onSubmitFormFail}
          requiredMark={true}
          scrollToFirstError
          className="space-y-5 p-5"
        >
          <Form.Item
            name="nameId"
            initialValue={
              node?.data?.nameId || formData?.nameId || node.id || ""
            }
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold capitalize">
                {t("automation.step")} {t("column_labels.name").toLowerCase()}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: `${t("automation.step")} ${t("column_labels.name")}`,
                }),
              },
              {
                required: true,
                pattern: new RegExp(/^[a-z0-9].*[a-z0-9]$/g),
                message: t("automation.step_name_start_end"),
              },
              {
                validator(rule, value, callback) {
                  let nameList = [...stepNameList];
                  // const arrayIndex = nameList.indexOf(node?.data?.nameId);

                  // Remove current node name in the array to exclude from exist check
                  nameList = nameList.filter((v) => v !== node?.data?.nodeId);

                  if (nameList.includes(value)) {
                    return Promise.reject(
                      t("validations.already_exists", {
                        value: `${t("automation.step")} ${t(
                          "column_labels.name"
                        ).toLowerCase()}`,
                      })
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder={t("validations.valid", {
                value: `${t("automation.step")} ${t(
                  "column_labels.name"
                ).toLowerCase()}`,
              })}
              onChange={(e) => {
                const newValue = String(e.target.value)
                  .toLowerCase()
                  .replace(/ /g, "_");
                form.setFieldsValue({ nameId: newValue.trim() });
                onChangeFormValue("nameId", newValue.trim());
              }}
            />
          </Form.Item>
          {/* <Form.Item
            name="name"
            initialValue={node?.data?.name || form?.name || ""}
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                Name of this step
              </h3>
            }
            rules={[{ required: true, message: "Step name is required!" }]}
          >
            <Input
              ref={sourceInputRef}
              placeholder="Send Hello SMS"
              onChange={(e) => onChangeFormValue("name", e.target.value)}
              maxLength="25"
            />
          </Form.Item> */}
          <Form.Item
            name="subAccountId"
            initialValue={
              node?.data?.subAccountId ||
              formData?.subAccountId ||
              elements["trigger"]?.data.subAccountId ||
              subaccountOptions[0]?.SubAccountId ||
              ""
            }
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                {t("fields.subaccount")}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("fields.subaccount"),
                }),
              },
            ]}
          >
            <Select
              name="smsFormSubaccount"
              showSearch
              placeholder={t("validations.select", {
                value: t("fields.subaccount"),
              })}
              filterOptions={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => onChangeFormValue("subAccountId", value)}
              className="w-60 block"
            >
              {subaccountOptions.map((account) => (
                <Option
                  value={account.SubAccountId}
                  key={account.SubAccountUid}
                >
                  {account.SubAccountId}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="source"
            initialValue={node?.data?.source || form?.source || ""}
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                {t("fields.sender_id")}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("fields.sender_id"),
                }),
              },
            ]}
          >
            <Input
              ref={sourceInputRef}
              placeholder={t("automation.enter_or_drag")}
              onChange={(e) => onChangeFormValue("source", e.target.value)}
              onDragEnter={() => sourceInputRef.current.focus()}
              onDragOver={onDragOverInput}
              onDrop={(e) => onDropInput(e, "source")}
            />
          </Form.Item>
          <Form.Item
            name="destination"
            initialValue={node?.data?.destination || form?.destination || ""}
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                {t("column_labels.destinations").split("|")[0]}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("column_labels.destinations").split("|")[0],
                }),
              },
              {
                validator(rule, value, callback) {
                  const isVariable = String(value || "").match(/^{{.*}}$/g);
                  if (!isVariable && !parsePhoneNumber(value).valid) {
                    return Promise.reject(
                      t("validations.invalid", {
                        value: t("fields.phone_number"),
                      })
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              ref={destinationInputRef}
              placeholder={t("automation.enter_or_drag")}
              onChange={(e) => onChangeFormValue("destination", e.target.value)}
              onDragEnter={() => destinationInputRef.current.focus()}
              onDragOver={onDragOverInput}
              onDrop={(e) => onDropInput(e, "destination")}
            />
          </Form.Item>
          <Form.Item
            name="text"
            initialValue={node?.data?.text || form?.text || ""}
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                {" "}
                {t("column_labels.message")}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("column_labels.message"),
                }),
              },
            ]}
          >
            <TextArea
              ref={textInputRef}
              xx
              onChange={(e) => onChangeFormValue("text", e.target.value)}
              onDragEnter={() => textInputRef.current.focus()}
              onDragOver={onDragOverInput}
              onDrop={(e) => onDropInput(e, "text")}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              disabled={!form.isFieldsTouched(false)}
            >
              {t("actions.update")}
            </Button>
            <Popconfirm
              placement="top"
              title={t("confirmations.delete2", { item: t("automation.step") })}
              onConfirm={onConfirmDelete}
              okText={`${t("actions.delete")} ${t("automation.step")}`}
              cancelText={t("actions.cancel")}
            >
              <Button type="text" className="w-full mt-2" danger>
                {t("actions.delete")}
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </section>
    </>
  );
};

export default SmsForm;
