import React, { useState } from "react";

import { DatePicker, Radio, Select, Input, Badge, Tooltip } from "antd";
import Moment from "moment";
import { useTranslation, Trans } from "react-i18next";

const { Option } = Select;

const { RangePicker } = DatePicker;

const statuses = [
  { name: "All", code: "All", namets: "column_labels.all" },
  { name: "Enabled", code: "A", namets: "app_labels.enabled" },
  { name: "Disabled", code: "I", namets: "app_labels.disabled" },
];

const triggers = [
  { name: "All", code: "All", namets: "column_labels.all" },
  { name: "SMS", code: "inbound_sms", namets: "sidebar_menu_parent.SMS" },
  {
    name: "Chat Apps",
    code: "inbound_chat_apps",
    namets: "sidebar_menu_parent.Chat apps",
  },
  {
    name: "Http Request",
    code: "http_request",
    namets: "automation.triggers.http_request",
  },
];

export default function Filters(props) {
  const { t } = useTranslation();
  const {
    filters,
    setFilter,
    reloadFilter,
    definitions,
    allDefinitions,
    filteredDefinitions,
    filterCount,
    loading,
  } = props;

  const { datefrom, dateto, trigger, status } = filters;

  const [isShowMoreFilters, showMoreFilters] = useState(false);

  const statusOptions = statuses.map((v) => (
    <Option value={v.code} key={v.code}>
      <span class="capitalize">{t(v.namets)}</span>
    </Option>
  ));

  const triggerOptions = triggers.map((v) => (
    <Option value={v.code} key={v.code}>
      {t(v.namets)}
    </Option>
  ));

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > Moment().endOf("day");
  }

  return (
    <div class="">
      <div class="flex px-3 py-2">
        <div class="w-3/5 flex">
          <Input
            size="large"
            disabled={loading}
            prefix={<i class="icon-novo-search" />}
            class="inline-block flex-auto"
            placeholder={t("automation.search_def_name")}
            onChange={(ev) => {
              setFilter({ search: ev.target.value.trim() });
            }}
          />
        </div>
        <div class="px-5 flex-auto flex items-center">
          <Badge status="warning" count={filterCount} className="filter">
            <span
              role="button"
              tabindex={0}
              class="cursor-pointer icon-novo-filter text-2xl p-1 hover-icon"
              onClick={() => {
                const isHide = !isShowMoreFilters;
                showMoreFilters(isHide);
              }}
            />
          </Badge>
          <span class="ml-6 text-base">
            {(definitions.length || 0).toLocaleString()}{" "}
            {t("app_labels.results")} of{" "}
            {(allDefinitions.length || 0).toLocaleString()}
          </span>
        </div>
        <div class="flex-shrink px-4 border-grey-lighter border-l flex items-center">
          <Tooltip placement="top" title={t("actions.refresh")}>
            <span>
              <span
                role="button"
                tabindex={0}
                class="icon-novo-refresh text-2xl cursor-pointer hover-icon"
                onClick={() => {
                  reloadFilter();
                }}
              />
            </span>
          </Tooltip>
          <Tooltip placement="top" title={t("actions.full_download")}>
            <span class="ml-5">
              <span class="icon-novo-download text-2xl cursor-not-allowed hover-icon text-grey" />
            </span>
          </Tooltip>
        </div>
      </div>
      {isShowMoreFilters ? (
        <div class="flex py-3 border-t border-grey-lighter px-3">
          <div class="flex-1">
            <span class="text-xs block mb-2">
              {t("column_labels.date_created")} (UTC+08:00)
            </span>
            <RangePicker
              disabled={loading}
              onChange={(d) => {
                setFilter({ datefrom: d[0], dateto: d[1] });
              }}
              disabledDate={disabledDate}
              defaultValue={[Moment(datefrom), Moment(dateto)]}
            />
          </div>
          <div class="flex-1 ml-2">
            <span class="text-xs block mb-2">{t("column_labels.status")}</span>
            <Select
              value={status}
              disabled={loading}
              className="w-full"
              onChange={(v) => {
                setFilter({ status: v });
              }}
            >
              {statusOptions}
            </Select>
          </div>
          <div class="flex-1 ml-2">
            <span class="text-xs block mb-2">
              {" "}
              {t("automation.automation_trigger")}
            </span>
            <Select
              value={trigger}
              disabled={loading}
              className="w-full"
              onChange={(v) => {
                setFilter({ trigger: v });
              }}
            >
              {triggerOptions}
            </Select>
          </div>
          <div class="flex-shrink flex items-center justify-center px-4 py-2">
            <span
              role="button"
              tabindex={0}
              class="no-underline text-sm mt-1 text-blue hover:text-blue-light"
              onClick={() => {
                setFilter({
                  datefrom: Moment().subtract(6, "month").startOf("day"),
                  dateto: Moment().endOf("day"),
                  trigger: "All",
                  status: "All",
                  pageSize: 10,
                  currentPage: 1,
                });
              }}
            >
              {t("actions.clear_all")}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}
