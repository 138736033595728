/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useDynamicRefs from "use-dynamic-refs";
import { useTranslation, Trans } from "react-i18next";

import { Form, Button, Popconfirm, Select, Input, message } from "antd";
const { Option } = Select;

// import { FlattenObject as flattenObject } from "@/utils/FlattenObject";
import { getUploadUrl, request } from "@/store/reducers/commons";

import Variables from "@/components/variables";

import voiceProfiles from "@/json/voice-profiles";
import voiceActions from "@/json/voice-actions";
import languages from "@/json/languages";

import CommonFields from "./fields";

import { DecodeJwt as decodeJwt } from "@/utils/jwt";

import dtmfHelper from "@/utils/dtmf-helper";
import branchHelper from "@/utils/branch-helper";

const initialValues = {
  nameId: "",
  subAccountId: "",
  // clientRequestId: "",
  action: "say",
  source: "",
  destination: "",
  text: "",
  language: "en-US",
  voiceProfile: "en-US-AriaRUS",
  speed: 1,
  repetition: 1,
  fileUrl: "",
  // dtmfCallbackUrl: "",
  minDigits: 1,
  maxDigits: 1,
  digitTimeout: 5000,
  overallTimeout: 5000,
  completeOnHash: true,
  noOfTries: 1,
  endMessage: "",
  dtmfValues: [
    { condition: "==", value: undefined },
    { condition: "!=", value: undefined },
  ],
};

const VoiceForm = ({
  node,
  onUpdateNodeValue,
  sidebarScrollWrapper,
  onDeleteNode,
  stepNameList,
  onUpdateStepNameList,
  loading,
  subaccountOptions,
}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const [getRef, setRef] = useDynamicRefs();

  const { elements } = useSelector((state) => state.flow);

  const [defaultValues, setDefaultValues] = useState(initialValues);
  const [action, setAction] = useState("");
  const [formIsTouched, setFormIsTouched] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [variables, setVariables] = useState({});

  // Get all outputs from parent nodes and set it as `variables`
  useEffect(() => {
    setVariables({ currId: node.id, elements, currOutputs: {} });
  }, [node, elements]);

  // Set default Values
  useEffect(() => {
    const savedformData = { ...node?.data };

    const {
      nameId,
      subaccountId,
      clientRequestId,
      params: p,
      action,
      selectNextStep,
    } = savedformData;

    const params = p && Object.keys(p).length ? p : {};

    const formData = {
      nameId: nameId || node.id || "",
      subAccountId:
        subaccountId ||
        (subaccountOptions && subaccountOptions.length
          ? subaccountOptions[0].SubAccountId
          : "") ||
        "",
      // clientRequestId: clientRequestId || "",
      action: action || "say",
      ...params,
    };

    const obj = {
      ...initialValues,
      ...formData,
    };

    if (selectNextStep && Object.keys(selectNextStep).length) {
      obj.dtmfValues = Object.keys(selectNextStep).reduce((a, b) => {
        const val = branchHelper.extractStepData(selectNextStep[b]);
        const newVal = {};

        newVal.condition = val.condition;
        newVal.value = val.dtmfValue;

        a.push(newVal);
        return a;
      }, []);
    }

    setDefaultValues({ ...obj });
    setAction(obj.action || "say");
  }, []);

  // Re-render fields if selectedNode is updated but has same type with previous selectedNode
  useEffect(() => {
    form.resetFields();

    sidebarScrollWrapper.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [defaultValues]);

  const onReset = () => {
    form.setFieldsValue({ source: "" });
    form.setFieldsValue({ destination: "" });
    form.setFieldsValue({ text: "" });
    form.setFieldsValue({ language: "en-US" });
    form.setFieldsValue({ voiceProfile: "en-US-AriaRUS" });
    form.setFieldsValue({ speed: 1 });
    form.setFieldsValue({ repetition: 1 });
    form.setFieldsValue({ fileUrl: "" });

    // DTMF fields
    // form.setFieldsValue({ dtmfCallbackUrl: "" });
    form.setFieldsValue({ minDigits: 1 });
    form.setFieldsValue({ maxDigits: 1 });
    form.setFieldsValue({ digitTimeout: 5000 });
    form.setFieldsValue({ overallTimeout: 5000 });
    form.setFieldsValue({ completeOnHash: true });
    form.setFieldsValue({ noOfTries: 1 });
    form.setFieldsValue({ endMessage: "" });
  };

  const onSubmitForm = () => {
    form
      .validateFields()
      .then((formValues) => {
        // Update store node data
        const { id } = node;
        const newData = { ...elements[id] };

        const {
          subAccountId,
          action,
          // clientRequestId,
          source,
          destination,
          text,
          speed,
          voiceProfile,
          fileUrl,
          repetition,
          nameId,
          // dtmfCallbackUrl,
          minDigits,
          maxDigits,
          digitTimeout,
          overallTimeout,
          completeOnHash,
          noOfTries,
          endMessage,
          dtmfValues,
        } = formValues;

        const inputs = {
          nameId,
          subaccountId: subAccountId,
          action,
          // clientRequestId,
          params: {
            source,
            destination,
          },
        };

        let selectNextStep = {};

        switch (action) {
          case "say": {
            inputs.params = {
              ...inputs.params,
              text,
              speed,
              voiceProfile,
              repetition,
            };
            break;
          }

          case "playFile": {
            inputs.params = {
              ...inputs.params,
              repetition,
              fileUrl,
            };
            break;
          }

          case "say&capture": {
            inputs.params = {
              ...inputs.params,
              text,
              speed,
              voiceProfile,
              repetition,
              // dtmfCallbackUrl,
              minDigits,
              maxDigits,
              digitTimeout,
              overallTimeout,
              completeOnHash,
              noOfTries,
              endMessage,
              dtmfValues,
            };

            selectNextStep = dtmfHelper.dtmfFormDataToSelectNextStep(
              dtmfValues,
              nameId
            );

            break;
          }

          default:
        }

        const outputs = {
          // [`${nameId}_msisdn`]: "{{data.payload.user.msisdn}}",
          [`${nameId}_step_dtmf`]: "{{step.dtmfData.actionDetails.dtmf}}",
          [`${nameId}_step_clientRequestId`]: "{{step.response.uid}}",
        };

        const data = {
          id,
          inputs,
          outputs,
          selectNextStep,
        };

        // console.log("data", data);

        onUpdateNodeValue({ ...data }, node);

        delete newData.nodeId;

        // Update the master list of step names
        let nameList = [...stepNameList];
        const arrayIndex = nameList.indexOf(node?.data?.nameId || id);
        nameList.splice(arrayIndex, 1, formValues?.nameId);
        onUpdateStepNameList(nameList);
      })
      .catch((e) => {
        // console.log("error", e);
        if (window.Bugsnag) {
          window.Bugsnag.notify(e);
        }
        notification.error({
          title: t("errors.unable_to_update", {
            value: t("app_labels.form").toLowerCase(),
          }),
        });
      });
  };

  const onSubmitFormFail = (values) => {
    // console.log("Failed to submit form");
  };

  const onConfirmDelete = () => {
    onDeleteNode(node.id || "", node);
  };

  const onDragStartTag = (e) => {
    e.dataTransfer.setData("variable", e.target.dataset.value);
  };

  const onDragOverInput = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDropInput = (e, fieldItem) => {
    e.preventDefault();
    const variable = e.dataTransfer.getData("variable");
    const { value, selectionEnd, selectionStart } = e.target;
    const newValue =
      value.slice(0, selectionStart) + variable + value.slice(selectionEnd);

    form.setFieldsValue({ [fieldItem]: newValue });
  };

  const onValuesChange = (f) => {
    // console.log("onValues cahnge", f);
  };

  const onFieldsChange = () => {
    setFormIsTouched(true);
  };

  const onUploadFile = async (file) => {
    const { name, type } = file;

    try {
      setUploading(true);
      const { payload } = await dispatch(getUploadUrl({ file }));
      const { uploadUrl, dbUrl, mimeType } = payload;

      const storageOrigin = uploadUrl ? uploadUrl.split("/")[2] : ""; // If from S3 or Google Cloud Storage
      const headers =
        storageOrigin === "storage.googleapis.com"
          ? {
              "Content-Type": "application/octet-stream",
            }
          : {
              "Content-Type": mimeType,
              "Content-Disposition": `attachment;filename="${name}"`,
            };

      const uploadReq = {
        method: "PUT",
        url: uploadUrl,
        data: file,
        headers,
      };

      await dispatch(request(uploadReq));

      // Decode jwt and determine region based on RegionId
      const token = localStorage.getItem("WWW-Authenticate");
      const decodedData = decodeJwt(token);
      let regionId = "";

      if (decodedData && Object.keys(decodedData).includes("RegionId")) {
        const { RegionId: region } = decodedData;
        regionId = region;
      }

      let rootUrl = "";
      if (process.env.TARGET === "development") {
        if (regionId === 0) {
          rootUrl = process.env.API_URL_V2;
        } else if (regionId === 1) {
          rootUrl = process.env.API_URL_ID;
        } else if (regionId === 2) {
          rootUrl = process.env.API_URL_UK;
        }
      } else {
        let envVar = "";
        if (regionId === 0) {
          envVar = process.env.API_URL_V2;
        } else if (regionId === 1) {
          envVar = process.env.API_URL_ID;
        } else if (regionId === 2) {
          envVar = process.env.API_URL_UK;
        }
        rootUrl = regionId ? envVar : window.location.origin;
      }

      const fileUrl = `${rootUrl}${dbUrl}?regionId=${regionId}`;

      return fileUrl;
    } catch (err) {
      throw new Error(err);
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <header className="bg-gradient-to-tr from-blue-500 to-indigo-500 px-5 py-7 w-96">
        <small className="text-xs text-red-200 tracking-widest">
          {t("sidebar_menu_parent.Voice")} {t("column_labels.message")}
        </small>
        <h1 className="font-bold text-lg text-white tracking-wider pr-20 leading-snug">
          {t("automation.configure_your", {
            item: t("sidebar_menu_parent.Voice").toLowerCase(),
          })}
        </h1>
      </header>
      <section className="w-96">
        <div className="bg-gray-100 p-5 pb-0">
          <h3 className="text-blue-500 text-xs mb-1 font-bold">
            {t("column_labels.variables")}
          </h3>
          <p className="text-sm text-gray-800">
            {t("automation.pick_and_drag")}{" "}
            <strong>`{t("column_labels.from")}`</strong>{" "}
            {t("column_labels.field")}
          </p>
        </div>
        <Variables
          className="bg-gray-100 p-4 sticky top-0 z-10"
          variables={variables}
          handleDragStart={onDragStartTag}
        />
        <Form
          form={form}
          layout="vertical"
          name="voice-form"
          autoComplete="off"
          onFinish={onSubmitForm}
          onFinishFailed={onSubmitFormFail}
          onValuesChange={onValuesChange}
          onFieldsChange={onFieldsChange}
          requiredMark={true}
          scrollToFirstError
          className="space-y-5 p-5"
          initialValues={{ ...defaultValues }}
        >
          <Form.Item
            name="nameId"
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold capitalize">
                {t("automation.step")} {t("column_labels.name").toLowerCase()}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: `${t("automation.step")} ${t("column_labels.name")}`,
                }),
              },
              {
                required: true,
                pattern: new RegExp(/^[a-z0-9].*[a-z0-9]$/g),
                message: t("automation.step_name_start_end"),
              },
              {
                validator(rule, value) {
                  let nameList = [...stepNameList];

                  // Remove current node name in the array to exclude from exist check
                  nameList = nameList.filter((v) => v !== node?.data?.nodeId);

                  if (nameList.includes(value)) {
                    return Promise.reject(
                      t("validations.already_exists", {
                        value: `${t("automation.step")} ${t(
                          "column_labels.name"
                        )}`,
                      })
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder={t("validations.valid", {
                value: `${t("automation.step")} ${t(
                  "column_labels.name"
                ).toLowerCase()}`,
              })}
              onChange={(e) => {
                const newValue = String(e.target.value)
                  .toLowerCase()
                  .replace(/ /g, "_");
                form.setFieldsValue({ nameId: newValue.trim() });
              }}
            />
          </Form.Item>
          <Form.Item
            name="subAccountId"
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                {t("validations.select", {
                  value: `${t("sidebar_menu_parent.Voice").toLowerCase()} ${t(
                    "fields.subaccount"
                  ).toLowerCase()}`,
                })}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("fields.subaccount"),
                }),
              },
            ]}
          >
            <Select
              disabled={loading}
              loading={loading}
              name="voiceFormSubaccount"
              showSearch
              placeholder={t("validations.select", {
                value: t("fields.subaccount").toLowerCase(),
              })}
              className="w-60 block"
            >
              {subaccountOptions.map((account) => (
                <Option
                  value={account?.SubAccountId}
                  key={account.SubAccountUid}
                >
                  {account.SubAccountId}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item
            name="clientRequestId"
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                Client Request Id
              </h3>
            }
          >
            <Input
              ref={setRef("clientRequestId")}
              placeholder="Client Request ID (optional)"
              onDragEnter={() => getRef("clientRequestId").current.focus()}
              onDragOver={onDragOverInput}
              onDrop={(e) => onDropInput(e, "clientRequestId")}
            />
          </Form.Item> */}
          <Form.Item
            name="action"
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                {t("column_labels.action")}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("column_labels.action"),
                }),
              },
            ]}
          >
            <Select
              name="voiceActions"
              showSearch
              placeholder={t("validations.select", {
                value: t("column_labels.action").toLowerCase(),
              })}
              onChange={(value) => {
                setAction(value);
                onReset();
              }}
              className="w-60 block"
            >
              {voiceActions.map((item) => (
                <Option value={item.name} key={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <CommonFields
            form={form}
            defaultValues={defaultValues}
            onDragStartTag={onDragStartTag}
            onDragOverInput={onDragOverInput}
            onDropInput={onDropInput}
            onUploadFile={onUploadFile}
            voiceProfiles={voiceProfiles}
            languages={languages}
            setRef={setRef}
            getRef={getRef}
            action={action}
            uploading={uploading}
          />

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              disabled={!formIsTouched || uploading || loading}
            >
              {t("actions.update")}
            </Button>
            <Popconfirm
              placement="top"
              title={t("confirmations.delete2", { item: t("automation.step") })}
              onConfirm={onConfirmDelete}
              okText={`${t("actions.delete")} ${t("automation.step")}`}
              cancelText={t("actions.cancel")}
            >
              <Button type="text" className="w-full mt-2" danger>
                {t("actions.delete")}
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </section>
    </>
  );
};

export default VoiceForm;
