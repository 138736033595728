import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import http from "@/utils/http-request";

const initialState = {
  templates: [],
  enums: {},
  templatesLoading: false,
  enumsLoading: false,
};

export const get = createAsyncThunk(
  "user/get",
  async (payload, { dispatch, rejectWithValue }) => {
    const { channelId } = payload;

    try {
      const { data } = await http.v2.get(
        `/chats/templates?channelId=${channelId}&status=APPROVED`
      );

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getEnums = createAsyncThunk(
  "user/getEnums",
  async (payload, { dispatch, rejectWithValue }) => {
    const { channelId } = payload;

    try {
      const { data } = await http.v2.get(
        `/chats/templates/enums?channelId=${channelId}`
      );

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const definitionsSlice = createSlice({
  name: "user",

  initialState,
  // reducers actions
  reducers: {},
  extraReducers: {
    [get.pending]: (state, action) => {
      state.templatesLoading = true;
    },
    [get.fulfilled]: (state, action) => {
      const data = action.payload;
      state.templates = data;
      state.templatesLoading = false;
    },
    [get.rejected]: (state, action) => {
      state.templatesLoading = false;
    },

    [getEnums.pending]: (state, action) => {
      state.enumsLoading = true;
    },
    [getEnums.fulfilled]: (state, action) => {
      const data = action.payload;
      state.enums = data;
      state.enumsLoading = false;
    },
    [getEnums.rejected]: (state, action) => {
      state.enumsLoading = false;
    },
  },
});

const { reducer } = definitionsSlice;

export default reducer;
