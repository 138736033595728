/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import axios from "axios";

export default function SecureImage(props) {
  const [secureUrl, setSecureUrl] = useState();

  // Fetch image everytime 'source' changes
  useEffect(() => {
    setSecureUrl("");

    // Cancel if url is empty or undefined
    if (!props.source) return;

    // Dont fetch via GET if url is enternal (not saved in S3 or GCP)
    const headerOrigin = `https://${
      (props.source || "").toString().split("/")[2]
    }`;
    const externalUrl =
      headerOrigin !== process.env.API_URL_ID &&
      headerOrigin !== process.env.API_URL_V2 &&
      headerOrigin !== process.env.API_URL_UK;

    if (!externalUrl) {
      fetchImage(props.source);
    } else {
      setSecureUrl(props.source);
    }
  }, [props.source]);

  const fetchImage = async (url) => {
    try {
      // Fetch file from secured url
      const jwt = localStorage.getItem("WWW-Authenticate");
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      setSecureUrl(data?.url || "");
    } catch (err) {
      console.error(err);
    }
  };

  return <img {...props} src={secureUrl} alt={secureUrl} />;
}
