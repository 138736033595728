import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTransition, animated } from "react-spring";
import { Input, message, Button, notification } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useZoomPanHelper, useStoreState } from "react-flow-renderer";
import dagre from "dagre";
import { useTranslation, Trans } from "react-i18next";

import Flow from "./flow";
import TriggerForm from "./forms/trigger";
import SmsForm from "./forms/sms";
import WaitReplyForm from "./forms/waitReply";
import HttpRequestForm from "./forms/httpRequest";
import WorkflowFormModal from "./forms/workflowForm";
import PreWorkflowModal from "./forms/preWorkflow";
import ChatAppForm from "./forms/chatApp";
import BranchForm from "./forms/branch";
import VoiceForm from "./forms/voice";
import StepMenu from "@/components/stepMenu";
import Header from "@/components/header";
import Information from "@/components/definitionInformation";
import Note from "@/components/note";

import { getTemplates } from "@/utils/get-templates";
import { showError } from "@/utils/handle-error-msg";

const templates = getTemplates();

import {
  updateNode,
  removeNode,
  updateElements,
  updateSelectedNode,
  initalizeSelectedWorkflow,
} from "@/store/reducers/flow";
import {
  getSmsSubaccounts,
  getCaSubaccounts,
  getVoiceSubaccounts,
  getTimezones,
  getAccess,
} from "@/store/reducers/user";

import { find, create, update } from "@/store/reducers/workflows/definitions";

import {
  ApiToMapData as apiToMapData,
  MapToFlowData as mapToFlowData,
  GetLayoutedElements as getLayoutedElements,
  MapToApiData as mapToApiData,
} from "../../utils/FlowUtils";

import nodes from "@/json/nodes";

import branchHelper from "@/utils/branch-helper";
import { download } from "@/utils/convert-json-to-file";
import { getRandomId } from "@/utils/common";

const expandableNode = [
  "trigger",
  "sms",
  "httpRequest",
  "waitReply",
  "chatApp",
  "voice",
  "branch",
  "converse",
];

const NODE_HEIGHT = 400;
const NODE_WIDTH = 300;

export default function Builder(props) {
  const { setCenter } = useZoomPanHelper();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reactFlowWrapperRef = useRef(null);
  const sidebarScrollWrapper = useRef(null);

  const { elements, selectedNode, selectedWorkflow } = useSelector(
    (state) => state.flow
  );
  const {
    smsSubaccounts,
    caSubaccounts,
    voiceSubaccounts,
    voiceLoading,
    caLoading,
    timezones,
  } = useSelector((state) => state.user);

  const workflowDefinitions = useSelector((state) => state.workflowDefinitions);
  const { loading } = workflowDefinitions;

  const [definitionId, setDefinitionId] = useState("");

  const [customWorkflowVisible, setCustomWorkflowVisible] = useState(false);
  const [workflowVisible, setWorkflowVisible] = useState(true);

  useEffect(() => {
    const hideLoadingMessage = message.loading(t("wait.loading"), 0);

    (async () => {
      try {
        dispatch(getAccess());
        dispatch(getTimezones());
        dispatch(getSmsSubaccounts());
        dispatch(getCaSubaccounts());
        dispatch(getVoiceSubaccounts());

        const query = new URLSearchParams(props.location.search);
        const id = query.get("definition_id");
        const templateId = query.get("template_id");

        let def = null;

        if (id) {
          const data = await dispatch(find({ id }));
          def = data && (data?.payload || []).length ? data.payload[0] : null;
        }

        if (templateId) {
          def = templates.find(
            (v) => Number(v.templateId) === Number(templateId)
          );
        }

        if (def && !def.subAccountId) {
          notification.error({
            message: t("automation.sub_id_required"),
          });
        }

        // Redirect to create
        if ((!id || !templateId) && !def) {
          window.history.replaceState(
            null,
            null,
            "/automation/builder?action=create"
          );
          return;
        }

        setDefinitionId(id || "");
        dispatch(initalizeSelectedWorkflow(def));
      } catch (e) {
        console.error(e);
      } finally {
        setTimeout(hideLoadingMessage, 0);
      }
    })();

    return () => {
      dispatch(initalizeSelectedWorkflow({}));
    };
  }, [dispatch, props.location.search]);

  // original json structure before there were any changes
  const [isSaved, setIsSaved] = useState(false);
  const [originalElement, setOriginalElement] = useState();
  useEffect(() => {
    if ((!originalElement && Object.keys(elements || {}).length) || isSaved) {
      setOriginalElement(JSON.stringify(elements));
      setIsSaved(false);
    }
  }, [elements, isSaved]);

  useEffect(() => {
    // Call API on mount and set flow data
    const mapData = apiToMapData(selectedWorkflow);

    // Extract custom variables from children
    let newElements = { ...mapData };
    // if (mapData?.trigger?.data?.trigger === "http_request") {
    //   const extractedVariables = extractHttpVariables(mapData);
    //   newElements.trigger.outputs = {
    //     ...newElements.trigger.outputs,
    //     ...extractedVariables,
    //   };
    // }

    dispatch(updateElements(newElements));
  }, [selectedWorkflow, dispatch]);

  // Initialize name list
  const [stepNameList, setStepNameList] = useState([]);
  useEffect(() => {
    if (!Object.keys(elements).length) return [];

    // Get all the nameId of all elements
    const nameIdList = Object.keys(elements).map(
      (elKey) => elements[elKey]?.inputs?.nameId || elKey
    );

    setStepNameList(nameIdList);
  }, [elements]);

  // const extractHttpVariables = (elements) => {
  //   const jsonElements = JSON.stringify(elements);
  //   const rx = /{{data.payload.(.*?)}}/g;
  //   const extractVariables = jsonElements.match(rx);
  //   const variables = (extractVariables || []).reduce((acc, curr) => {
  //     const name = curr.replace(rx, "$1");

  //     // Ignore 'source' and 'destination' since they are reserved variables
  //     if (name === "source" || name === "destination") return acc;

  //     return {
  //       ...acc,
  //       [name]: curr,
  //     };
  //   }, {});
  //   return variables;
  // };

  const mapToFlowWithHandlers = (elements) => {
    return mapToFlowData(elements, {
      handleShowNodeDetail: (node) => {
        // Center node if selected
        if (reactFlowInstance) {
          const { zoom } = reactFlowInstance.toObject();

          setCenter(
            node.xPos + NODE_WIDTH / 2,
            node.yPos + NODE_HEIGHT / 2,
            zoom
          );

          if (expandableNode.includes(node?.type)) {
            dispatch(updateSelectedNode(node));
            setShowSidebar(true);
          } else {
            setShowSidebar(false);
          }
        }
      },
      handleUpdateNodeValue: (newNode) => {
        onUpdateNodeValue(newNode);
      },
      handleHideSidebar: (node) => {
        setShowSidebar(false);
      },
      handleCreateNewNode: (type, parent) => {
        const { id: parentId, ...data } = parent;
        const parentNode = { ...data };

        // Use unix timestamp as id for new node to prevent duplicate
        const nodeId = `${(type || "").toLowerCase()}_${getRandomId()}`;

        let newNode = { ...nodes[type].template };
        newNode.source = [parentId];
        newNode.type = type;
        newNode.inputs = { ...newNode.inputs, nameId: nodeId };

        let affectedStep = null;

        if (parentNode.target.length && parentNode.target.length === 1) {
          if (type.toLowerCase() === "jumpto") {
            notification.error({
              message: t("automation.cant_insert_jump_step"),
            });
            return;
          }
          newNode.target = [...parentNode.target];

          affectedStep = { ...elements[parentNode.target[0]] };

          parentNode.target = [nodeId];

          affectedStep.source = [nodeId];
        } else {
          parentNode.target = [...parentNode.target, nodeId];
        }

        const elementsObj = {
          [parentId]: parentNode,
          [nodeId]: newNode,
        };

        if (affectedStep && affectedStep?.inputs?.nameId) {
          elementsObj[affectedStep.inputs.nameId] = affectedStep;
        }

        dispatch(updateElements(elementsObj));
      },
      handleDeleteNode: ({ id }) => {
        onDeleteNode(id);
      },
      stepNameList: stepNameList,
      onUpdateStepNameList: (name) => {
        setStepNameList(name);
      },
    });
  };

  // Initialize dagre graph
  const [dagreGraph, setDagreGraph] = useState(null);
  useEffect(() => {
    const newGraph = new dagre.graphlib.Graph();
    newGraph.setDefaultEdgeLabel(() => ({}));
    setDagreGraph(newGraph);
  }, []);

  const flowData = useMemo(() => {
    if (!Object.keys(elements).length || !dagreGraph) return [];

    const flowDataWithEventHandlers = mapToFlowWithHandlers(elements);

    return getLayoutedElements(dagreGraph, flowDataWithEventHandlers, "TB");
  }, [elements, dagreGraph]);

  const onUpdateEdge = (data) => {
    const { source, target, sourceHandle } = data;
    // Add new target node into target array
    let newSourceData = { ...elements[source] };

    // If node doesn't contain sourceHandle then do nothing
    // You need to create a custom node for this type of node
    if (!sourceHandle) {
      return;
    }

    // Remove the old target from the source node based on target 'handle' named alphabetically
    const oldTargetIndex = sourceHandle.charCodeAt() - 97;
    const prevTarget = newSourceData.target[oldTargetIndex];

    // remove the previous target's source
    if (!newSourceData.target.includes(target)) {
      const sourceTargets = [...newSourceData.target];
      sourceTargets.splice(oldTargetIndex, 1, target);
      newSourceData.target = sourceTargets;
    } else {
      // If 'target' is already in source 'target' list
      const sourceTargets = [...newSourceData.target];
      const targetIndex = sourceTargets.indexOf(target);

      if (newSourceData.branch) {
        // If node is branch; then swap target position and replace prevTarget to 'EMPTY'
        sourceTargets.splice(oldTargetIndex, 1, target);
        sourceTargets.splice(targetIndex, 1, "EMPTY");
      } else {
        // If node is not a branch; then simply remove the prevTarget
        sourceTargets.splice(targetIndex, 1);
      }
      newSourceData.target = sourceTargets;
    }

    // If parent node is branchChild type then allow multiple connection
    const parentIsChildBranchNode =
      elements[data.source].type === "ChildBranch";

    // Update target node's new source
    const newTargetData = { ...elements[target] };
    const prevSource = String(newTargetData?.source || []);
    // Allow only same `source` to nodes that are branchable (e.g. BRANCH, and WAIT FOR REPLY)
    const newSourceValue =
      newTargetData.source === source && !newSourceData.branch ? "" : source;
    // ToDo: Enable this once we allow childBranch node to connect to a node that has an existing source
    // const newTargetDataValues = new Set([
    //   ...(newTargetData.source || []),
    //   newSourceValue,
    // ]);
    // newTargetData.source = [...newTargetDataValues];
    if (newTargetData.source === source && !newSourceData.branch) {
      newTargetData.source = [];
    } else {
      newTargetData.source = [source];
    }

    // Update previous source if available
    let newPrevTarget = {};
    let tempPrevTarget = {};
    if (prevTarget) {
      tempPrevTarget = { ...elements[prevTarget] };
      tempPrevTarget.source = [];

      newPrevTarget = { [prevTarget]: { ...tempPrevTarget } };
    }

    let newPrevSource = {};
    let tempPrevSource = {};
    if (prevSource.length) {
      tempPrevSource = { ...elements[prevSource] };
      // ToDo: Enable this once we allow childBranch node to connect to a node that has an existing source
      // const newTargets = parentIsChildBranchNode
      //   ? [...(tempPrevSource.target || []), data.target]
      //   : [...(tempPrevSource.target || [])];
      const newTargets = [...(tempPrevSource.target || [])];
      newTargets.splice(newTargets.indexOf(target), 1);
      tempPrevSource.target = newTargets;

      newPrevSource = {
        [prevSource]: {
          ...tempPrevSource,
        },
      };
    }

    // If prev source and prev target is same, merge both in 'newPrevTarget'
    if (prevSource === prevTarget) {
      // Remove source and target if previousTarget and prevSource is the same
      newPrevSource = {
        [prevSource]: {
          ...tempPrevSource,
          source: [],
          target: [],
        },
      };
      newPrevTarget = {};
    }

    // Remove prevSource from newTarget's source list
    if (prevTarget === target) {
      const newSourceList = [...newTargetData.source];
      const index = newSourceList.indexOf(prevSource);
      newSourceList.splice(index, 1);
      newTargetData.source = [...newSourceList];
    }

    // if (!newTargetData?.inputs?.nameId) {
    //   newTargetData.inputs = {
    //     ...newTargetData.inputs,
    //     nameId: `${(newTargetData.type || "").toLowerCase()}_${getRandomId()}`,
    //   };
    // }

    dispatch(
      updateElements({
        ...newPrevSource,
        ...newPrevTarget,
        [source]: newSourceData,
        [target]: newTargetData,
      })
    );

    // setShowSidebar(false);

    return true;
  };

  const onUpdateNodePosition = ({ id, position }) => {
    // Only update position of nodes that are created by drag and drop
    if (!("position" in elements[id])) return;

    dispatch(
      updateElements({
        ...elements,
        [id]: {
          ...elements[id],
          position,
        },
      })
    );
  };

  // Handle selected node
  const [showSidebar, setShowSidebar] = useState(false);
  const transition = useTransition(showSidebar, {
    from: {
      opacity: 0,
      x: 50,
    },
    enter: {
      opacity: 1,
      x: 0,
    },
    leave: (item) => {
      return {
        opacity: 0,
        x: 50, // Set `selectedNode` to empty once sidebar animation is done
        onRest: () => item && dispatch(updateSelectedNode({})),
      };
    },
  });

  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  // Handle remove node
  const onDeleteNode = (id, node) => {
    if (!id) return;

    let newParentTarget = [];

    if (node && node.data && node.data.target.length) {
      const children = node.data.target;

      if (children.length === 1) {
        newParentTarget = elements[children[0]].target;
      }

      const hasChildBranches = children.some(
        (v) => elements[v]?.type.toLowerCase() === "childbranch"
      );

      if (hasChildBranches) {
        children.forEach((v) => {
          dispatch(removeNode({ id: v }));
        });
      }
    }

    dispatch(removeNode({ id, newTarget: newParentTarget }));

    setShowSidebar(false);

    // Create new dagre instance to forget the positions of delete nodes
    const newGraph = new dagre.graphlib.Graph();
    newGraph.setDefaultEdgeLabel(() => ({}));
    setDagreGraph(newGraph);
  };

  // Handle add node
  const onCreateNode = (
    type,
    { position, source = [], target = [], id = "", inputs = {} }
  ) => {
    // Use unix timestamp as id for new node to prevent duplicate
    const nodeId = id || `${(type || "").toLowerCase()}_${getRandomId()}`;

    let newNode =
      nodes[type] && nodes[type].template ? { ...nodes[type].template } : {};
    newNode.source = source || [];
    newNode.type = type;
    newNode.target = target || [];

    if (position) newNode.position = position;

    // this is applicable for branching
    newNode.inputs = inputs;

    const newNodeObj = {
      [nodeId]: newNode,
    };

    dispatch(updateElements(newNodeObj));
  };

  const createChildNodes = (value, node) => {
    let disconnectedChildBranchTargets = [];

    if (value.selectNextStep) {
      if (node && node.data && node.data.target.length) {
        node.data.target.forEach((v) => {
          if (elements[v]?.type.toLowerCase() === "childbranch") {
            disconnectedChildBranchTargets =
              disconnectedChildBranchTargets.concat(elements[v]?.target || []);
            dispatch(
              removeNode({ id: v, newTarget: [...(elements[v]?.target || [])] })
            );
          }
        });
      }

      const { selectNextStep } = value;
      const targets = [];

      Object.keys(selectNextStep).forEach((v, i) => {
        const source = [value.id];

        let id = `childbranch${i}_${getRandomId()}`;

        targets.push(id);

        const step = branchHelper.extractStepData(selectNextStep[v]);

        const { conditionName, condition } = value.inputs;

        const inputs = { conditionName, condition, index: i, ...step };

        onCreateNode("ChildBranch", {
          source,
          id,
          inputs,
          target:
            elements[disconnectedChildBranchTargets[i]] &&
            value?.inputs?.conditionName === node?.data?.inputs.conditionName
              ? [disconnectedChildBranchTargets[i]]
              : [],
          affectedNodes: disconnectedChildBranchTargets,
          index: i,
        });

        // update childbranch target without source
        if (elements[disconnectedChildBranchTargets[i]]) {
          const childBranchTargetWithoutSource = {
            ...elements[disconnectedChildBranchTargets[i]],
          };

          childBranchTargetWithoutSource.source = [id];

          const elementsObj = {
            [disconnectedChildBranchTargets[i]]: childBranchTargetWithoutSource,
          };

          dispatch(updateElements(elementsObj));
        }
      });

      value.target = targets;

      // change the selectNextStep Object keys to child id
      value.selectNextStep = Object.keys(selectNextStep).reduce((a, b, i) => {
        a[targets[i]] = selectNextStep[b];
        return a;
      }, {});

      dispatch(updateNode(value));
    }
  };

  //@davy-for-review Handle update node value
  const onUpdateNodeValue = (value, node) => {
    if (value.selectNextStep && Object.keys(value.selectNextStep).length) {
      const nodeDataSns = (node && node.data && node.data.selectNextStep) || {};

      // create new nodes
      if (
        !value.inputs ||
        Object.keys(value.selectNextStep).length !== nodeDataSns.length ||
        value?.inputs?.conditionName !== node?.data?.inputs.conditionName
      ) {
        createChildNodes(value, node);
      } else {
        // update old nodes
        dispatch(updateNode(value));

        if (node && node.data && node.data.target.length) {
          const step = {};
          Object.keys(value.selectNextStep).forEach((sv, idx) => {
            step[idx] = branchHelper.extractStepData(value.selectNextStep[sv]);
            // console.log("branchhelper data", step[idx]);
          });
          node.data.target.forEach((v, i) => {
            const { conditionName, condition } = value.inputs;

            dispatch(
              updateNode({
                id: v,
                inputs: { conditionName, condition, index: i, ...step[i] },
              })
            );
          });
        }
      }
    } else {
      dispatch(updateNode(value));
    }

    setShowSidebar(false);
    const newGraph = new dagre.graphlib.Graph();
    newGraph.setDefaultEdgeLabel(() => ({}));
    setDagreGraph(newGraph);
  };

  let sidebarContent = null;
  if (selectedNode.type === "trigger") {
    sidebarContent = (
      <TriggerForm
        node={selectedNode}
        smsSubaccountOptions={smsSubaccounts}
        chatSubaccountOptions={caSubaccounts}
        onUpdateNodeValue={onUpdateNodeValue}
        sidebarScrollWrapper={sidebarScrollWrapper}
      />
    );
  } else if (selectedNode.type === "sms") {
    sidebarContent = (
      <SmsForm
        node={selectedNode}
        subaccountOptions={smsSubaccounts}
        onUpdateNodeValue={onUpdateNodeValue}
        onDeleteNode={onDeleteNode}
        sidebarScrollWrapper={sidebarScrollWrapper}
        stepNameList={stepNameList}
        onUpdateStepNameList={setStepNameList}
      />
    );
  } else if (selectedNode.type === "waitReply") {
    sidebarContent = (
      <WaitReplyForm
        node={selectedNode}
        onUpdateNodeValue={onUpdateNodeValue}
        onDeleteNode={onDeleteNode}
        sidebarScrollWrapper={sidebarScrollWrapper}
        stepNameList={stepNameList}
        onUpdateStepNameList={setStepNameList}
      />
    );
  } else if (selectedNode.type === "httpRequest") {
    sidebarContent = (
      <HttpRequestForm
        node={selectedNode}
        subaccountOptions={smsSubaccounts}
        onUpdateNodeValue={onUpdateNodeValue}
        onDeleteNode={onDeleteNode}
        sidebarScrollWrapper={sidebarScrollWrapper}
        stepNameList={stepNameList}
        onUpdateStepNameList={setStepNameList}
      />
    );
  } else if (selectedNode.type === "chatApp") {
    sidebarContent = (
      <ChatAppForm
        node={selectedNode}
        loading={caLoading}
        subaccountOptions={caSubaccounts}
        getSubaccounts={() => dispatch(getCaSubaccounts())}
        onUpdateNodeValue={onUpdateNodeValue}
        onDeleteNode={onDeleteNode}
        sidebarScrollWrapper={sidebarScrollWrapper}
        stepNameList={stepNameList}
        onUpdateStepNameList={setStepNameList}
      />
    );
  } else if (selectedNode.type === "voice") {
    sidebarContent = (
      <VoiceForm
        node={selectedNode}
        loading={voiceLoading}
        subaccountOptions={voiceSubaccounts}
        onUpdateNodeValue={onUpdateNodeValue}
        onDeleteNode={onDeleteNode}
        sidebarScrollWrapper={sidebarScrollWrapper}
        stepNameList={stepNameList}
        onUpdateStepNameList={setStepNameList}
      />
    );
  } else if (selectedNode.type === "branch") {
    sidebarContent = (
      <BranchForm
        node={selectedNode}
        timezones={timezones}
        onUpdateNodeValue={onUpdateNodeValue}
        onDeleteNode={onDeleteNode}
        onCreateNode={onCreateNode}
        reactFlowInstance={reactFlowInstance}
        reactFlowWrapper={reactFlowWrapperRef}
        sidebarScrollWrapper={sidebarScrollWrapper}
        stepNameList={stepNameList}
        onUpdateStepNameList={setStepNameList}
      />
    );
  }

  const onSaveWorkflow = async () => {
    const definition = mapToApiData(elements);

    // Assign the definition id based on the defintionId state
    definition.definition.id = definitionId;

    if (!definition.subAccountId) {
      notification.error({ title: t("automation.sub_id_form_required") });

      return;
    }

    const saveMethod = definitionId ? update(definition) : create(definition);
    const hideLoadingMessage = message.loading(t("wait.saving"), 0);

    try {
      const response = await dispatch(saveMethod).unwrap();
      if (Object.keys(response).includes("error")) {
        throw response?.payload;
      }

      const newDefinitionId = response?.id || "";
      // Update url `definition_id` query to the new `id` generated in the API
      window.history.replaceState(
        null,
        null,
        `/automation/builder?definition_id=${newDefinitionId}`
      );
      // Update the value of definitionId
      setDefinitionId(newDefinitionId);

      const data = await dispatch(find({ id: newDefinitionId }));

      const def = data && (data?.payload || []).length ? data.payload[0] : null;

      if (def) {
        dispatch(initalizeSelectedWorkflow(def));
      }

      setIsSaved(true);
      notification.success({
        message: t("success.saved", {
          value: t("automation.workflow").toUpperCase(),
        }),
      });
    } catch (err) {
      if (typeof err.error === "string" || err.error instanceof String) {
        notification.error({ message: err.error });
      } else {
        notification.error({
          message: t("success.saved", {
            value: err.error || t("automation.failed_saved"),
          }),
        });
        showError(err, t);
      }
    } finally {
      setTimeout(hideLoadingMessage, 0);
    }
  };

  return (
    <div
      ref={reactFlowWrapperRef}
      className="bg-white absolute w-full h-screen inset-0 z-9 overflow-hidden"
    >
      {!loading && !Object.keys(selectedWorkflow || {}).length ? (
        <div>
          <PreWorkflowModal
            visible={workflowVisible}
            templates={[]}
            handleCancel={() => history.push("/")}
            handleForward={() => {
              setCustomWorkflowVisible(true);
              setWorkflowVisible(false);
            }}
          />
          <WorkflowFormModal
            visible={customWorkflowVisible}
            selectedWorkflow={selectedWorkflow}
            handleSetWorkflow={(workflow) => {
              dispatch(initalizeSelectedWorkflow(workflow));
              setCenter(NODE_WIDTH / 2, NODE_HEIGHT / 2, 1.0);
            }}
            handleCancel={() => {
              setCustomWorkflowVisible(false);
              setWorkflowVisible(true);
            }}
            smsSubaccountOptions={smsSubaccounts}
            chatSubaccountOptions={caSubaccounts}
          />
        </div>
      ) : (
        ""
      )}
      <Header
        className="absolute left-0 z-50"
        style={{ top: "-12px" }}
        loading={loading}
        handleSave={onSaveWorkflow}
        elements={elements}
        originalElements={originalElement}
      />
      <aside className="absolute z-10 top-16 left-5">
        <h1 className="text-gray-700 bg-gray-200 py-1 px-3 text-xs rounded-full font-bold tracking-wide inline-flex items-center space-x-1 mt-5 mb-3">
          {t("automation.def_info")}
        </h1>
        {selectedWorkflow.definition &&
        selectedWorkflow.definition.steps.length ? (
          <Button
            className="ml-3"
            type="default"
            onClick={() => {
              const { definition, subAccountId, trigger, status } =
                selectedWorkflow;

              const obj = { definition, subAccountId, trigger, status };

              download(obj, "application/json", definition.name);
            }}
            size="small"
          >
            {t("actions.download")}
          </Button>
        ) : null}
        <Information loading={loading} elements={elements} />
        <h1 className="text-gray-700 bg-gray-200 py-1 px-3 text-xs rounded-full font-bold tracking-wide inline-flex items-center space-x-1 mt-10 mb-3">
          {t("automation.steps")}
        </h1>
        <Note type="tip" className="mb-5">
          {t("automation.drag_drop_workflow.0")} <br />{" "}
          {t("automation.drag_drop_workflow.1")}
        </Note>
        <div
          className="inline-block relative max-h-full overflow-auto w-60"
          style={{ maxHeight: "calc(100vh - 200px)" }}
        >
          {/* <div className="w-96">
            <code className="whitespace-pre">
              {JSON.stringify(stepNameList, null, 2)}
              {JSON.stringify(elements, null, 2)}
            </code>
          </div> */}
          <StepMenu
            handleSelectStep={() => {}}
            className="h-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4"
          />
        </div>
      </aside>
      <Flow
        data={flowData}
        onUpdateEdge={onUpdateEdge}
        onUpdateNodePosition={onUpdateNodePosition}
        onCreateNode={onCreateNode}
        setShowSidebar={setShowSidebar}
        reactFlowWrapper={reactFlowWrapperRef}
        reactFlowInstance={reactFlowInstance}
        handleSetFlowInstance={setReactFlowInstance}
      />
      {transition((style, item) =>
        item ? (
          <animated.aside
            key="detail-sidebar"
            className="absolute z-10 top-16 right-5"
            style={{ ...style }}
          >
            <div
              ref={sidebarScrollWrapper}
              className="relative overflow-auto max-h-full bg-white rounded shadow"
              style={{ maxHeight: "calc(100vh - 150px)" }}
            >
              {sidebarContent}           
            </div>
          </animated.aside>
        ) : (
          ""
        )
      )}
    </div>
  );
}
