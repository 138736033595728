import chatappsAutoreply from "@/json/templates/chatapps-auto-reply.json";
import smsAutoReply from "@/json/templates/sms-auto-reply.json";
import menuUsingWaitforReply from "@/json/templates/menu-using-waitforreply.json";
import keywordDetection from "@/json/templates/keyword-detection.json";
import outOfOfficeDynamic from "@/json/templates/out-of-office-dynamic.json";
import outOfOfficeWeekend from "@/json/templates/out-of-office-weekend.json";
import signUpFlow from "@/json/templates/signup-flow.json";
import csatVoice from "@/json/templates/csat-voice.json";
import guessingGame from "@/json/templates/guessing-game.json";
import optOut from "@/json/templates/opt-out.json";

export const getTemplates = () => {
  return [
    chatappsAutoreply,
    smsAutoReply,
    menuUsingWaitforReply,
    keywordDetection,
    outOfOfficeDynamic,
    outOfOfficeWeekend,
    signUpFlow,
    csatVoice,
    guessingGame,
    optOut,
  ];
};
