import React, { useState, useEffect } from "react";
import {
  Button,
  message,
  Popover,
  Dropdown,
  Menu,
  Icon,
  No,
  notification,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";

import Definitions from "./features/definitions.js";
import Usage from "./features/usage.js";
import Templates from "./features/templates.js";
import Filters from "./features/filters.js";
import Uploader from "./features/uploader.js";
import Pagination from "./features/pagination.js";

import { download } from "@/utils/convert-json-to-file";
import { showError } from "@/utils/handle-error-msg";

import { useTranslation, Trans } from "react-i18next";

import {
  get,
  update,
  remove,
  setFilter,
  executeFilter,
  create,
} from "@/store/reducers/workflows/definitions";

import { getUsage } from "@/store/reducers/workflows/usage";

export default function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [defaultFilters, setDefaultFilters] = useState({});
  const [filterCount, setFilterCount] = useState(0);

  const [usageLoading, setUsageLoading] = useState(false);

  const [buttonAction, setButtonAction] = useState("1");

  const [modalVisible, setModal] = useState(false);

  const workflowDefinitions = useSelector((state) => state.workflowDefinitions);
  const workflowUsage = useSelector((state) => state.workflowUsage);

  const {
    uploading,
    filters,
    allDefinitions,
    filteredDefinitions,
    definitions,
    loading,
  } = workflowDefinitions;
  const { usage } = workflowUsage;

  useEffect(() => {
    (async () => {
      try {
        setUsageLoading(true);
        await dispatch(getUsage());
      } catch (e) {
        notification.error("Unable to get worflow usage.");
      } finally {
        setUsageLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(get());
        dispatch(executeFilter());
      } catch (e) {
        notification.error("Unable to get worflows.");
      }
    })();
  }, []);

  useEffect(() => {
    if (!Object.keys(defaultFilters).length) {
      try {
        setDefaultFilters(JSON.parse(JSON.stringify(filters)));
      } catch (e) {}
    }
  }, []);

  useEffect(() => {
    const defaultF = defaultFilters;
    const touchedF = filters;

    // if default filters are not in object
    if (!Object.keys(defaultF).length) return setFilterCount(0);

    // else
    // get the difference between default and touched filters

    const countedFilters = ["dateto", "datefrom", "trigger", "status"];

    const diffFilters = countedFilters.reduce(
      (a, b) => {
        if (Object.keys(defaultF).some((f) => f === b)) {
          if (["dateto", "datefrom"].includes(b)) {
            // eslint-disable-next-line no-param-reassign
            a.default[b] = Moment(defaultF[b]).format("MM/DD/YYYY");
            // eslint-disable-next-line no-param-reassign
            a.touched[b] = Moment(touchedF[b]).format("MM/DD/YYYY");
          } else {
            // eslint-disable-next-line no-param-reassign
            a.default[b] = defaultF[b];
            // eslint-disable-next-line no-param-reassign
            a.touched[b] = touchedF[b];
          }
        }

        return a;
      },
      { default: {}, touched: {} }
    );

    diffFilters.default.daterange = `${diffFilters.default.datefrom}-${diffFilters.default.datefrom}`;
    diffFilters.touched.daterange = `${diffFilters.touched.datefrom}-${diffFilters.touched.datefrom}`;

    delete diffFilters.default.dateto;
    delete diffFilters.default.datefrom;
    delete diffFilters.touched.dateto;
    delete diffFilters.touched.datefrom;

    const affectedFilters = Object.keys(diffFilters.default).reduce((a, b) => {
      if (diffFilters.default[b] !== diffFilters.touched[b]) {
        a.push(b);
      }

      return a;
    }, []);

    setFilterCount(affectedFilters.length);
  }, [filters]);

  const content = (
    <div style={{ width: "310px" }}>
      {t("automation.desc")}
      <br></br>
      {t("automation.sub_desc.0")}{" "}
      <a
        href="https://developer.8x8.com/connect/docs/automation-builder"
        rel="noopener nofollow"
        target="_blank"
      >
        {t("automation.sub_desc.1")}
      </a>{" "}
      {t("automation.sub_desc.2")}.
    </div>
  );

  const actionItems = [
    {
      label: t("automation.create_custom_worflow"),
      key: "1",
    },
    {
      label: t("automation.upload_workflow"),
      key: "2",
    },
  ];

  const handleActionMenuClick = (v) => {
    setButtonAction(v.key);
  };

  const handleActionClick = (v) => {
    if (buttonAction === "1") {
      history.push("/builder?action=create");
    }

    if (buttonAction === "2") {
      setModal(true);
    }
  };

  const menu = (
    <Menu onClick={handleActionMenuClick}>
      <Menu.Item key="1">
        <span role="button" tabIndex={0}>
          <i class="icon-novo-plus mr-1" />{" "}
          {t("automation.create_custom_worflow")}
        </span>
      </Menu.Item>

      <Menu.Item key="2">
        <span role="button" tabIndex={0}>
          <i class="icon-novo-cloud-unsynced mr-1" />{" "}
          {t("automation.upload_workflow")}
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="mb-10">
      <div class="flex py-5">
        <div class="flex-1">
          <h1 class="text-xl text-color font-semibold page-title pt-2 pb-2">
            <Popover
              content={content}
              title={t("automation.title")}
              trigger="hover"
              placement="right"
            >
              {t("automation.title")} 
              <i class="icon-novo-info-circle text-base font-semibold cursor-pointer ml-1" />
            </Popover>
          </h1>
        </div>
        <div class="flex-1 text-right">&nbsp;</div>
      </div>
      <div class="mb-10 mt-5 flex">
        <div class="flex-auto">
          <Usage usage={usage} loading={usageLoading} />
        </div>
        <div class="flex-shrink justify-end flex items-center">
          <div>
            <Dropdown.Button
              overlay={menu}
              size="large"
              type="primary"
              icon={<DownOutlined />}
              onClick={handleActionClick}
            >
              <i
                class={
                  buttonAction === "1"
                    ? "icon-novo-plus"
                    : "icon-novo-cloud-unsynced"
                }
              />{" "}
              <span class="ml-1 align-middle">
                {
                  actionItems.find((v) => {
                    return v.key === buttonAction;
                  })?.label
                }
              </span>
            </Dropdown.Button>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div class="border-grey-lighter border solid flex flex-col w-full relative rounded">
          <div class="flex-shrink border-b border-grey-lighter">
            <Filters
              setFilter={(obj) => {
                dispatch(setFilter(obj));
                dispatch(executeFilter());
              }}
              reloadFilter={() => {
                dispatch(executeFilter());
              }}
              filters={filters}
              filterCount={filterCount}
              definitions={definitions}
              allDefinitions={allDefinitions}
              loading={loading}
              filteredDefinitions={filteredDefinitions}
            />
          </div>
          <div>
            <Definitions
              loading={loading}
              filteredDefinitions={filteredDefinitions}
              allDefinitions={allDefinitions}
              definitions={definitions}
              filters={filters}
              setFilter={(obj) => {
                dispatch(setFilter(obj));
                dispatch(executeFilter());
              }}
              redirect={(v) => {
                history.push(`/builder?definition_id=${v?.definition?.id}`);
              }}
              download={async (v) => {
                const { definition, subAccountId, trigger, status } = v;

                const obj = { definition, subAccountId, trigger, status };

                download(obj, "application/json", definition.name);
              }}
              update={async (v) => {
                try {
                  await dispatch(update(v)).unwrap();

                  notification.success({
                    message: t("success.updated", { value: v.definition.name }),
                  });
                  await dispatch(get());

                  dispatch(executeFilter());
                } catch (e) {
                  notification.error({
                    message: t("errors.unable_to_update", {
                      value: v.definition.name,
                    }),
                  });

                  if (e.error) showError(e, t, { refreshPage: true });
                }
              }}
              remove={async (v) => {
                try {
                  await dispatch(
                    remove({
                      id: v.definition.id,
                      version: v.definition.version,
                    })
                  ).unwrap();
                  notification.success({
                    message: t("success.deleted", { value: v.definition.name }),
                  });
                  await dispatch(get());

                  dispatch(executeFilter());
                } catch (e) {
                  notification.error({
                    message: t("errors.unable_to_remove", {
                      value: v.definition.name,
                    }),
                  });

                  if (e.error) showError(e, t, { refreshPage: true });
                }
              }}
            />
          </div>
          <div class="flex-shrink px-3 py-2 border-t border-grey-lighter">
            <Pagination
              loading={loading}
              allDefinitions={allDefinitions}
              definitions={definitions}
              filteredDefinitions={filteredDefinitions}
              filters={filters}
              handlePage={(v) => {
                dispatch(setFilter({ currentPage: v }));
                dispatch(executeFilter());
              }}
              handlePageSize={(v) => {
                dispatch(setFilter({ pageSize: v }));
                dispatch(executeFilter());
              }}
            />
          </div>
        </div>
        <Templates />
      </div>
      <Uploader
        visible={modalVisible}
        setModal={({ visible }) => {
          setModal(visible);
        }}
        loading={uploading}
        createWorkflow={async (obj) => {
          try {
            const { id } = await dispatch(create(obj)).unwrap();

            setModal(false);
            history.push(`/builder?definition_id=${id}`);
          } catch (e) {
            notification.error({
              message: t("errors.unable_to_upload", {
                value: t("automation.workflow"),
              }),
              duration: 0,
            });

            if (e.error) showError(e, t);
          }
        }}
      />
    </div>
  );
}
